<template>
  <div
    class="flex flex-col items-center py-16 mobile:px-1 px-16 w-9/12 mx-auto mobile:w-full"
  >
    <div class="flex flex-col justify-center text-center">
      <span class="text-5xl font-semibold">Pole veel kindel?</span>
      <span class="text-3xl font-semibold mb-8"
        >Vaata meie klientide tagasisidet.</span
      >
    </div>
    <div class="flex w-full mb-24 mobile:flex-col mobile:w-full mobile:px-4">
      <div
        class="w-1/2 flex mobile:w-full mobile:flex-col mobile:mb-4 items-center pr-6 mobile:pr-0"
      >
        <div class="flex w-1/4 justify-center mobile:w-full pr-2 mobile:pr-0">
          <img src="/proto-front/partner-1.jpg" />
        </div>

        <div class="flex w-3/4 flex-col mobile:w-full pr-2 pl-4 mobile:px-0">
          <span class="font-semibold mb-2"
            >Sven Tenno - Haljastusteenused OÜ</span
          >
          <span
            >Pakutav tööhalduse lahendus on mugav ja vajalik abivahend enda
            tööde planeerimiseks ja teostamiseks. Hea ja läbimõeldud töövahend
            mis vastab hästi ettevõtte vajadustele.</span
          >
        </div>
      </div>
      <div
        class="w-1/2 flex mobile:w-full mobile:flex-col mobile:mb-4 items-center pl-6 mobile:pl-0"
      >
        <div class="flex w-1/4 justify-center mobile:w-full pr-2">
          <img src="/proto-front/sl-roof.png" />
        </div>

        <div class="flex w-3/4 flex-col mobile:w-full pl-4 mobile:pl-0">
          <span class="font-semibold mb-2">Jaan Palmiste - SL-Katus OÜ</span>
          <span
            >Juba pikemalt olen otsinud sellist keskkonda, kus saaks kõik oma
            kindlate projektidega seotud tegevused ühte kohta viia, nii et seda
            näeks klient ja kaastöötajad ka, ilma jamata. Gewodos on see
            võimalus hästi olemas.</span
          >
        </div>
      </div>
    </div>
    <div class="flex w-full mobile:w-full mobile:flex-col">
      <div
        class="w-7/12 flex flex-col mobile:w-full mobile:mb-8 mobile:items-center"
      >
        <span
          class="text-3xl font-semibold mobile:self-center mobile:text-center mobile:mb-6"
          >Huvitatud? <span class="text-green">Muidugi</span>!</span
        >
        <span class="mobile:px-4">
          Proovi Gewodot juba täna. Liitumine on lihtne, õppimine samuti. Vaid
          15 minutit ning Sul on mugavalt ülevaade kõigest vajalikust. Kui
          liitud kohe, siis saad 14 päeva Premium paketti tasuta. Vaata
          hinnastamist
          <router-link to="/pricing" class="hover:text-green">siit</router-link
          >.
        </span>
      </div>
      <div
        class="w-5/12 flex flex-col items-center mobile:w-full justify-center px-8 mobile:px-4"
      >
        <router-link
          :to="$store.state.auth.status.loggedIn ? '/calendar' : '/register'"
        >
          <button
            class="w-full text-2xl px-12 py-4 bg-green text-white font-medium hover:bg-darkgreen hover:shadow-outline"
          >
            Proovi TASUTA
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: ""
    };
  }
};
</script>
