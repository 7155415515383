<template>
  <div class="flex-col flex pt-16 bg-offwhite items-center">
    <span
      class="text-5xl font-semibold mb-8 mobile:px-4 mobile:text-4xl mobile:text-center"
      >Tee KÕIK <span class="text-green">lihtsamaks</span>.</span
    >
    <div class="flex w-full">
      <div
        class="flex w-9/12 pb-12 mx-auto mobile:w-full mobile:px-4 mobile:flex-col px-16"
      >
        <div
          class="flex w-5/12 mobile:w-full mobile:justify-center justify-start mobile:mb-4"
        >
          <img src="/process/1.png" class="rounded-xl proc-image" />
        </div>

        <div class="flex w-7/12 flex-col mobile:w-full">
          <span class="process-header"
            >Hoia silm peal. Igal pool, paari klikiga.</span
          >
          <span class="process-content"
            >Hoia silma peal oma ettevõtte projektidel ja ülesannetel, leia
            sobivaid auke uute tööde jaoks ning teeni maksimaalselt. Nii
            telefonis, kui arvutis - igal ajal, igal pool.</span
          >
          <div class="process-list">
            <div class="process-list-item">
              <span class="process-point typcn typcn-tick"></span>
              <span class="process-text"
                >Jälgi mugavalt päeva, nädala ja kuu kalendrivaadet.</span
              >
            </div>
            <div class="process-list-item">
              <span class="process-point typcn typcn-tick"></span>
              <span class="process-text"
                >Jälgi eraldi projekti ja töötajate kalendreid.</span
              >
            </div>
            <div class="process-list-item">
              <span class="process-point typcn typcn-tick"></span>
              <span class="process-text"
                >Määra korduvaid ülesandeid - säästa aega tüütult
                sisestamiselt.</span
              >
            </div>
            <div class="process-list-item">
              <span class="process-point typcn typcn-tick"></span>
              <span class="process-text"
                >Projekti dokumendid ja pildid ühes kohas - midagi ei kao kunagi
                ära ja on käe juures.</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex w-full bg-white">
      <div
        class="flex w-9/12 py-12 mx-auto mobile:w-full mobile:px-4 mobile:flex-col px-16"
      >
        <div class="flex w-7/12 flex-col mobile:w-full mobile:order-2">
          <span class="process-header"
            >Kogu töö ühes kohas. Pakkumisest arveni.</span
          >
          <span class="process-content"
            >Gewodos on Sul olemas kõik vajalik. Esimesest pakkumisest
            kliendile, kuni viimase arveni, ühes kohas, selgelt ja kiirelt
            kättesaadav.
          </span>
          <div class="process-list">
            <div class="process-list-item">
              <span class="process-point typcn typcn-tick"></span>
              <span class="process-text"
                >Koosta pakkumisi ja saada need välja otse Gewodost. Ühe
                klikiga.
              </span>
            </div>
            <div class="process-list-item">
              <span class="process-point typcn typcn-tick"></span>
              <span class="process-text"
                >Koosta arveid ja saada need välja otse Gewodost. Kõigest mõne
                klikiga.
              </span>
            </div>
            <div class="process-list-item">
              <span class="process-point typcn typcn-tick"></span>
              <span class="process-text"
                >Kopeeri pakkumine arveks. Sekundiga.</span
              >
            </div>
            <div class="process-list-item">
              <span class="process-point typcn typcn-tick"></span>
              <span class="process-text"
                >Saa ülevaade kõikidest arvetest ja pakkumistest. Ühe
                klikiga.</span
              >
            </div>
          </div>
        </div>
        <div
          class="flex w-5/12 mobile:w-full mobile:justify-center justify-end mobile:order-1 mobile:mb-4"
        >
          <img src="/process/2.png" class="rounded-xl proc-image" />
        </div>
      </div>
    </div>
    <div class="flex w-full">
      <div
        class="flex w-9/12 py-12 mx-auto mobile:w-full mobile:px-4 mobile:flex-col"
      >
        <div
          class="flex w-5/12 mobile:w-full mobile:justify-center justify-start mobile:mb-4 px-16 mobile:px-0"
        >
          <img src="/process/4.png" class="rounded-xl proc-image" />
        </div>

        <div class="flex w-7/12 flex-col mobile:w-full">
          <span class="process-header">Täielik ülevaade töötajatest.</span>
          <span class="process-content"
            >Oma töötajatel on keeruline silma peal hoida ka juba siis, kui neid
            on kolm. Ülesannete määramine, tööaja mõõtmine ja ülevaate saamine
            on paras peavalu. Aga mitte Gewodoga.</span
          >
          <div class="process-list">
            <div class="process-list-item">
              <span class="process-point typcn typcn-tick"></span>
              <span class="process-text"
                >Määra töötajaid ülesannetele. Ühe klikiga.</span
              >
            </div>
            <div class="process-list-item">
              <span class="process-point typcn typcn-tick"></span>
              <span class="process-text"
                >Jälgi töötajate töötunde. Detailselt ja ülesande
                põhiselt.</span
              >
            </div>
            <div class="process-list-item">
              <span class="process-point typcn typcn-tick"></span>
              <span class="process-text"
                >Arvesta automaatselt tunnilehti ja tööaega.</span
              >
            </div>
            <div class="process-list-item">
              <span class="process-point typcn typcn-tick"></span>
              <span class="process-text"
                >Suhtle töötajatega otse Gewodos ja ükski info ei lähe kunagi
                kaotsi.</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex w-full bg-white">
      <div
        class="flex w-9/12 pt-12 pb-16 mx-auto mobile:w-full mobile:px-4 mobile:flex-col px-16"
      >
        <div class="flex w-7/12 flex-col mobile:w-full mobile:order-2">
          <span class="process-header">Ligipääs kliendile. Mugavalt. </span>
          <span class="process-content"
            >Gewodos on ainulaadne kliendiportaal, kust Sinu klient näeb kõike,
            mis tema projektidega seotud on. Nii säästad närve ja aega kliendile
            piltide, info, töögraafikute ja failide saatmiselt.</span
          >
          <div class="process-list">
            <div class="process-list-item">
              <span class="process-point typcn typcn-tick"></span>
              <span class="process-text"
                >Kliendil on alati ülevaade ülesannetest ja projekti
                ajajoonest.</span
              >
            </div>
            <div class="process-list-item">
              <span class="process-point typcn typcn-tick"></span>
              <span class="process-text"
                >Faile ja pilte saad jagada mugavalt. Kõigest mõne
                klikiga.</span
              >
            </div>
            <div class="process-list-item">
              <span class="process-point typcn typcn-tick"></span>
              <span class="process-text"
                >Saad viia kogu suhtluse otse projekti alla vestlusesse.
              </span>
            </div>
            <div class="process-list-item">
              <span class="process-point typcn typcn-tick"></span>
              <span class="process-text"
                >Kõik kliendiga seotud pakkumised ja arved ühes kohas. Hetkega
                saadaval.</span
              >
            </div>
          </div>
        </div>
        <div
          class="flex w-5/12 mobile:w-full mobile:justify-center justify-end mobile:order-1 mobile:mb-4 "
        >
          <img src="/process/3.png" class="rounded-xl proc-image" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isProvider: false,
      email: ""
    };
  },
  components: {},
  methods: {
    toggleTab(target) {
      if (target === "provider") {
        this.isProvider = true;
      } else if (target === "consumer") {
        this.isProvider = false;
      }
    }
  }
};
</script>

<style lang="scss">
.process-header {
  @apply text-xl font-semibold mb-2;
}
.process-content {
  @apply mb-4 pr-4;
}
.process-list {
  @apply flex-col mb-4;
  .process-list-item {
    @apply flex items-center mb-3;
    .process-point {
      @apply rounded-full bg-green w-10 h-10 shadow flex justify-center items-center text-3xl leading-none text-white mr-3 p-2;
    }
    .process-text {
      @apply text-lightgrey flex-auto;
    }
  }
}
.proc-image {
  max-height: 350px;
}
@media screen and (max-width: 992px) {
  .process-list {
    .process-list-item {
      .process-point {
        @apply w-8 h-8 text-2xl mr-2 p-2;
      }
    }
  }
}
</style>
