<template>
  <div class="flex w-full bg-offwhite mobile:px-4">
    <div
      class="flex flex-col w-9/12 mobile:w-full mx-auto py-16 justify-center items-center"
    >
      <span class="text-5xl font-semibold">Ära oota!</span>
      <span class="text-3xl font-medium mb-8 mobile:text-center"
        >30 sekundit ja oledki Gewodos.</span
      >
      <router-link
        :to="$store.state.auth.status.loggedIn ? '/calendar' : '/register'"
        class="mb-2"
      >
        <button class="new-button-green">
          <span class="label text-2xl p-2">Proovi TASUTA</span>
        </button>
      </router-link>

      <span class="text-xs"
        >Proovi Gewodo tööhaldust <span class="text-green">TASUTA</span>.</span
      >
      <span class="text-xs mb-8 mx-10"
        >Ei mingit pangakaardi lisamist. Õpitav
        <span class="text-green">15 minutiga</span>.</span
      >
      <span class="text-3xl font-medium mb-4 mobile:text-center"
        >Tahad meie abi? Registreeru personaalsele kõnele.</span
      >
      <a
        href="https://calendly.com/gewodo/20mindemo"
        target="_blank"
        class="mobile:mx-8"
      >
        <button
          class="bg-green text-white text-2xl px-16 py-4 hover:bg-darkgreen hover:shadow-outline font-medium"
        >
          <span class="">Registreeru kõnele</span>
        </button>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: ""
    };
  }
};
</script>
